import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import BlocContactForm from "../components/Contact"
import Event from "../components/Contact/event"
import BannerV2 from "../components/elements/BannersV2/BannerV2"

const bannerLeftCardContent = {
  mainContent: [
    `Vous avez un projet ou un besoin d'accompagnement sur l'une ou plusieurs de nos
    expertises ? N'hésitez pas à nous en parler et nous reviendrons vers vous très rapidement.`,
    `Venez nous challenger, nous en ferons autant !`,
  ],
  titleFirstLine: "Une question ?",
  titleSecondLine: "Un projet ?",
  subTitle: "Contactez-nous dès maintenant !",
}

const PageContact = ({ location }) => {
  const data = useStaticQuery(
    graphql`
      query {
        contact: wordpressPage(
          id: { eq: "412e0a3c-3d39-57c8-b08f-9e8b26826d0a" }
        ) {
          acf {
            title
            meta_description
            titre_banniere
            texte_banniere
            hashtag
            image_banniere_new {
              localFile {
                childImageSharp {
                  fluid(quality: 100, maxWidth: 3000) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            adresse_evenement
            code_postal_rencontrons_nous
            date_evenement
            heure_evenement
            lien_evenement
            lieu_evenement
            logo_rencontrons_nous {
              localFile {
                childImageSharp {
                  fluid(quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            plan_rencontrons_nous {
              localFile {
                childImageSharp {
                  fluid(quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            rue_rencontrons_nous
            statut_evenement
            telephone_rencontrons_nous
            texte_formulaire_contact
            image_evenement {
              localFile {
                childImageSharp {
                  fluid(quality: 100) {
                    src
                  }
                }
              }
            }
            titre_evenement
            texte_evenement
            google_map_coordonnees
          }
        }
      }
    `
  )

  return (
    <Layout
      location={location.pathname}
      crumbLabel="Contactez-nous"
      locationGTM={location.href}
    >
      <SEO
        title={data.contact.acf.title}
        description={data.contact.acf.meta_description}
        lang="fr"
        url={location.pathname}
      />
      <BannerV2
        bannerLeftCardContent={bannerLeftCardContent}
        hasBreadCrumb
        isContactPage
      />
      <BlocContactForm
        location={location.href}
        logo={
          data.contact.acf.logo_rencontrons_nous.localFile.childImageSharp.fluid
        }
        map={
          data.contact.acf.plan_rencontrons_nous.localFile.childImageSharp.fluid
        }
        text={data.contact.acf.texte_formulaire_contact}
        rue={data.contact.acf.rue_rencontrons_nous}
        codePostal={data.contact.acf.code_postal_rencontrons_nous}
        tel={data.contact.acf.telephone_rencontrons_nous}
        coord={data.contact.acf.google_map_coordonnees}
      />
      {data.contact.acf.statut_evenement &&
        data.contact.acf.statut_evenement === "Publié" && (
          <Event
            banner={
              data.contact.acf.image_evenement.localFile.childImageSharp.fluid
                .src
            }
            titre={data.contact.acf.titre_evenement}
            texte={data.contact.acf.texte_evenement}
            date={data.contact.acf.date_evenement}
            heure={data.contact.acf.heure_evenement}
            adresse={data.contact.acf.adresse_evenement}
            lieu={data.contact.acf.lieu_evenement}
            lien={data.contact.acf.lien_evenement}
          />
        )}
    </Layout>
  )
}

export default PageContact
