import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"

import globalVariables from "../globalVariables"
import Section from "../elements/Section"
import Title from "../elements/Title"
import Button from "../elements/Button"
import ContactForm from "../elements/ContactForm"
import Phone from "../../images/icons/phone-bleu.svg"
import IconLieu from "../../images/icons/lieublanc.svg"
import IconPhone from "../../images/icons/phone.svg"

const Container = styled.div`
  display: flex;
  flex-direction: column;
  @media (min-width: ${globalVariables.minDesktop}) {
    flex-direction: row;
    min-height: 700px;
  }
`
const Meet = styled.div`
  display: flex;
  flex-direction: column;
  background: ${props => props.theme.primary};
  @media (min-width: ${globalVariables.medDesktop}) {
    flex: 0 0 50%;
  }
`

const WrapperContent = styled.div`
  padding: 50px 20px;
  .hidde-mobile {
    display: none;
  }
  .hidde-desktop {
    display: flex;
  }
  @media (min-width: ${globalVariables.minTablet}) {
    padding: 75px 50px 60px 50px;
    .hidde-mobile {
      display: flex;
    }
    .hidde-desktop {
      display: none;
    }
  }
`

const Row = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 70px;
  @media (min-width: ${globalVariables.minTablet}) {
    margin-top: 90px;
    flex-direction: row;
    align-items: stretch;
    justify-content: center;
  }
`

const Address = styled.div`
  color: white;
  margin-bottom: 0;
  padding: 1.5rem 1rem 1rem 1rem;
  span {
    font-size: 20px;
    display: block;
  }
  b {
    font-size: 25px;
  }
  span.btn-text {
    color: ${props => props.theme.secondary};
    font-size: 20px;
    font-weight: bold;
    margin-left: 5px;
  }
  a {
    margin-top: 1rem;
    &:hover {
      span.btn-text {
        color: ${props => props.theme.white};
      }
    }
  }
  @media (max-width: ${globalVariables.maxMobile}) {
    text-align: center;
  }
  @media (min-width: ${globalVariables.minTablet}) {
    border-left: 1px solid white;
    padding: 0 0 0 2rem;
    margin-left: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
`
const WrapperLogo = styled.div`
  width: 180px;
  height: 135px;
`
const WrapperImage = styled.a`
  display: block;
  width: 100%;
  height: 300px;
  @media (min-width: ${globalVariables.minTablet}) {
    height: 100%;
  }
`

const RowAddress = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`

const Tel = styled.p`
  font-size: 21px;
  font-weight: bold;
  margin-bottom: 0;
`

const WrapperIcon = styled.div`
  width: 30px;
  height: 30px;
  margin-right: 12px;
`

const DetailAdress = styled.div`
  margin-bottom: 1rem;
`

const BlocContactForm = ({
  logo,
  map,
  text,
  rue,
  codePostal,
  tel,
  coord,
  location,
}) => {
  return (
    <Section padding="0" color="greyLighter" border id="contact-bloc-1">
      <Container>
        <ContactForm
          id="contact-form"
          text={text}
          padding="80px 60px"
          location={location}
        />
        <Meet id="address">
          <WrapperContent>
            <Title
              color="white"
              className="title1 trait"
              traitWidth="300px"
              traitLeft="-50px"
            >
              Rencontrons-nous !
            </Title>
            <Row>
              <WrapperLogo>
                <Img fluid={logo} />
              </WrapperLogo>
              <Address>
                <RowAddress>
                  <WrapperIcon>
                    <img src={IconLieu} alt="adresse" />
                  </WrapperIcon>
                  <DetailAdress>
                    <span className="hidde-mobile">
                      <b>SLAP digital</b>
                    </span>
                    <span>{rue}</span>
                    <span>{codePostal}</span>
                  </DetailAdress>
                </RowAddress>
                <RowAddress className="hidde-mobile">
                  <WrapperIcon>
                    <img src={IconPhone} alt="adresse" />
                  </WrapperIcon>
                  <Tel>{tel.replace(/(\d)(?=(\d{2})+$)/g, "$1 ")}</Tel>
                </RowAddress>
                <Button
                  as="external-link"
                  title={tel.replace(/(\d)(?=(\d{2})+$)/g, "$1 ")}
                  href={"tel:+33" + tel}
                  icon={<img src={Phone} alt="numéro de téléphone" />}
                  colors="primary"
                  iconPosition="left"
                  className="hidde-desktop"
                />
              </Address>
            </Row>
          </WrapperContent>
          <WrapperImage href={coord} target="_blanck" rel="noopener norefferer">
            <Img fluid={map} />
          </WrapperImage>
        </Meet>
      </Container>
    </Section>
  )
}

export default BlocContactForm
